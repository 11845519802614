import React from "react";
import { Email } from "react-obfuscate-email";

const FormatContent = ({ content }) => {
  let isEmailAddress =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isPDFFile = /^.+\.pdf$/; // ends with ".pdf"

  if (isEmailAddress.test(content)) {
    return (
      <>
        email: <Email email={content}>{content}</Email>
      </>
    );
  } else if (isPDFFile.test(content)) {
    return (
      <>
        download: <a href={`./files/${content}`}>{content}</a>
      </>
    );
  } else {
    return content;
  }
};

export default FormatContent;
