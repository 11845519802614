import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "../components/bootstrap.min.css";
import "../components/styles.css";
import SimpleList from "../components/SimpleList";
import SingleProject from "../components/SingleProject";
import aboutMe from "../components/aboutMe.json";
import portfolio from "../components/portfolio.json";

const Portfolio = () => {
  const projectsArrayLength = portfolio.length;
  const chunkSize = 2;

  let a = portfolio.reduce(
    (acc, item) => {
      let group = acc.pop();
      if (group.length === chunkSize) {
        acc.push(group);
        group = [];
      }
      group.push(item);
      acc.push(group);
      return acc;
    },
    [[]]
  );

  let counter = 0;
  return a.map((doubleItem, key) => {
    return (
      <Row key={key} className={"bottomframes"}>
        {doubleItem.map((singleItem, key) => (
          <SingleProject
            key={key}
            singleItem={singleItem}
            counter={counter++}
            projectsArrayLength={projectsArrayLength}
          />
        ))}
      </Row>
    );
  });
};

const OneTopFrame = ({ column1, column2 }) => (
  <Col xl={6}>
    <div>
      <Container>
        <Row>
          <Col sm={6}>
            <SimpleList data={column1} />
          </Col>
          <Col sm={6}>
            <SimpleList data={column2} />
          </Col>
        </Row>
      </Container>
    </div>
  </Col>
);

const TopFrames = () => (
  <Row className={"topframes"}>
    <OneTopFrame
      column1={aboutMe.topFrames[0]}
      column2={aboutMe.topFrames[1]}
    />
    <OneTopFrame
      column1={aboutMe.topFrames[2]}
      column2={aboutMe.topFrames[3]}
    />
  </Row>
);

const Header = ({ lines }) => {
  return (
    <Row className={"padding15"}>
      <Col>
        {typeof lines[0] != "undefined" ? <h1>{lines[0]}</h1> : ""}
        {typeof lines[1] != "undefined" ? <h2>{lines[1]}</h2> : ""}
        {typeof lines[2] != "undefined" &&
        typeof lines[2].title != "undefined" &&
        typeof lines[2].linkAddress != "undefined" &&
        typeof lines[2].linkName != "undefined" ? (
          <div className="repoLink">
            {lines[2].title}{" "}
            <a
              className={`blank`}
              href={lines[2].linkAddress}
              target="_blank"
              rel="noopener noreferrer"
            >
              {lines[2].linkName}
            </a>
          </div>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

const MetaHelmet = ({ title }) => (
  <Helmet>
    <meta name="robots" content="noindex,nofollow" />
    <meta charSet="utf-8" />
    <meta lang="en" />
    <title>{title}</title>
  </Helmet>
);

const IndexPage = () => (
  <div className={"main"}>
    <MetaHelmet title={aboutMe.name} />
    <Container>
      <Header lines={[aboutMe.name]} />
      <TopFrames />
      <Header
        lines={[aboutMe.primaryTitle, aboutMe.secondaryTitle, aboutMe.github]}
      />
      <Portfolio />
    </Container>
  </div>
);

export default IndexPage;
