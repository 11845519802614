import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./styles.css";
import "react-lazy-load-image-component/src/effects/blur.css";

const Project = ({
  singleItem: { name, img, link },
  counter,
  projectsArrayLength,
}) => {
  return (
    <Col xl={6} id={`project_${counter}`} className={"singleProject"}>
      <div>
        <Container className={"padding15"}>
          <Row>
            <Col xs="9">
              <h3>
                {name}
                <span className={"fontWeightNormal"}>
                  {` (${projectsArrayLength - counter})`}
                </span>
              </h3>
            </Col>
            <Col xs="3" className={"textAlignRight"}>
              <a
                className={"blank"}
                href={link !== "" ? link : `./projects/${img}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                fullsize snapshot
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={`${"frame"} ${"margin15"}`}>
        <div>
          <LazyLoadImage
            src={`portfolio/${img}.webp`}
            afterLoad={() => {
              document.getElementById(`project_${counter}`).style.visibility =
                "visible";
            }}
            effect="blur"
            srcSet={`portfolio/${img}_508.webp 508w, portfolio/${img}_538.webp 538w, portfolio/${img}_618.webp 618w, portfolio/${img}_688.webp 688w, portfolio/${img}_928.webp 928w, portfolio/${img}.webp 508w`}
            sizes={`(max-width: 767px) 508px, 
            (min-width:768px) and (max-width: 991px) 688px,	
            (min-width:992px) and (max-width: 1199px) 928px, 
            (min-width:1200px) and (max-width: 1399px) 538px, 
            (min-width:1400px) 618px`}
            alt={img}
          />
        </div>
      </div>
    </Col>
  );
};

export default Project;
