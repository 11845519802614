import React from "react";
import FormatContent from "./FormatContent";
import "./styles.css";

const SimpleList = ({ data: { title, desc } }) => (
  <>
    <p className="title">{title}</p>
    <ul>
      {desc.map((item) => (
        <li key={item}>{<FormatContent content={item} />}</li>
      ))}
    </ul>
  </>
);

export default SimpleList;
